import React, { Component } from 'react';

import { Button, themes, ButtonContainer } from 'libs/ui';
import { FormBox, Header, CommentBox, CommentFieldName, CommentInput } from './styledComponents';

export default class ChangeStatusForm extends Component {
  state = { comment: '' };

  isBlocked = () => {
    return this.props.info.status === 'blocked' || this.props.info.status === 'blocked_by_client';
  };
  getHeader = () => {
    switch (this.props.type) {
      case 'rfid':
        return `RFID ${this.props.info.value}`;
      case 'epm':
        return `БСК ${this.props.info.value}`;
      default:
        return '';
    }
  };
  getButtonName = () => {
    let formatType = this.props.type === 'rfid' ? 'RFID' : 'БСК';
    if (this.isBlocked()) {
      return `Разблокировать ${formatType}`;
    } else {
      return `Заблокировать ${formatType}`;
    }
  };
  setCommentsValue = (value) => {
    this.setState({ comment: value });
  };
  getChangedStatus = () => {
    if (this.isBlocked()) {
      return 'active';
    } else {
      return 'blocked';
    }
  };
  changeStatus = () => {
    this.props.removeFormInfo();
    const params = {
      id: this.props.info.id,
      status: this.getChangedStatus(),
      comment: this.state.comment,
    };
    if (this.isBlocked()) {
      this.props.changeStatus(params);
      this.cancelForm();
    } else {
      this.props.setWarning(this.props.info.value, params);
    }
  };
  cancelForm = () => {
    this.props.removeFormInfo();    
  };

  render() {
    console.log('🚀 ~ status', this.props);
    return (
      <FormBox type={this.props.type}>
        <Header>{this.getHeader()}</Header>
        <CommentBox>
          <CommentFieldName>Комментарий</CommentFieldName>
          <CommentInput
            placeholder="Напишите свой комментарий"
            value={this.state.comment}
            onChange={(e) => this.setCommentsValue(e.target.value)}
          ></CommentInput>
        </CommentBox>
        <ButtonContainer space="20px" justifyContent="flex-end">
          <Button onClick={this.cancelForm}>Отменить</Button>
          <Button
            theme={this.isBlocked() ? themes.green : themes.red}
            onClick={this.changeStatus}
            status={this.props.info.status}
          >
            <img width="10" src="/images/icon_lock.png" alt="" />
            {this.getButtonName()}
          </Button>
        </ButtonContainer>
      </FormBox>
    );
  }
}
