import React, { Component } from 'react';
import { reduxForm } from 'redux-form';

import { Filters } from 'libs/form';

class Filter extends Component {
  render() {
    const { handleSubmit, reset, type } = this.props;
    
    const filters = [
      { type: 'input', w: 160, name: 'id', title: 'ID', placeholder: '3786467864675656' },
      { type: 'dict', w: 140, dict: 'clients.status' },
      { type: 'dict', w: 120, dict: 'clients.type' },
      { type: 'input', w: 290, name: 'name', title: 'Наименование', placeholder: 'Иванов И.И.' },
      { type: 'input', w: 290, name: 'contact_name', title: 'Контактное лицо', placeholder: 'Иванов И.И.' },
      { type: 'input', w: 160, name: 'tel_num', title: 'Телефон', placeholder: '+7 956 456-56-78' },
    ];

    return <Filters items={filters} page={type} handleSubmit={handleSubmit} reset={reset} />;
  }
}

export default reduxForm({
  form: 'clientsFilter',
  destroyOnUnmount: false,
  initialValues: { status: 'active' },
})(Filter);
