import React, { Component } from 'react';

import { TableHeader } from 'libs/ui'
import Ride from './Row';
import {
  GridBox,
  Grid,
  TableHead,
  TableHeadRow,
  TopRow,
} from 'libs/ui';
import { Total } from 'libs/ui';

export default class RidesGrid extends Component {
  getRidesList = () => {
    const rides = this.props.entries || this.props.rides;
    let ridesList = [];
    if (rides) {
      for (let key in rides) {
        ridesList.push(<Ride zones={this.props.zones} key={rides[key].id} {...rides[key]} />);
      }
    }
    return ridesList;
  };

  render() {
    return (
      <GridBox>
        <TopRow>
          <Total cnt={this.props.count} />
          {this.props.getXlsLink()}
        </TopRow>
        <Grid>
          <TableHead>
            <TableHeadRow>
              {window.config.central && <TableHeader width="140px">Парковка</TableHeader>}
              {/*<TableHeader width='105px'>Проезд</TableHeader>*/}
              <TableHeader width="210px" sortField="timestamp">
                Время
              </TableHeader>
              <TableHeader width="100px">Результат</TableHeader>
              <TableHeader width="75px">Тип</TableHeader>
              <TableHeader width="62px">Зона из</TableHeader>
              <TableHeader width="95px">Узел/стойка</TableHeader>
              <TableHeader width="62px">Зона в</TableHeader>
              <TableHeader width="70px">Класс ТС</TableHeader>
              <TableHeader width="135px" sortField="vehicle_plate">Госномер</TableHeader>
              <TableHeader width="150px">Тип СО</TableHeader>
              <TableHeader width="150px">СО</TableHeader>
              <TableHeader width="135px">Держатель</TableHeader>
              <TableHeader>Клиент</TableHeader>
              <TableHeader width="80px">Действия</TableHeader>
            </TableHeadRow>
          </TableHead>
          <tbody>{this.getRidesList()}</tbody>
        </Grid>
      </GridBox>
    );
  }
}
