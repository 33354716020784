import { user, data } from 'services';
import { GridRow, Cell, OptionsCell } from 'libs/ui';
import { getDataField } from 'libs/common';
import { api } from 'api';

/**
 * row
 * @param {*} props
 * @returns
 */
const Row = (props) => {
  const afterSubmit = () => {
    props.getEntries({ offset: 0 });
  };

  /**
   * edit
   */
  const editEntity = () => {
    props.setItemInfo({ ...props });
    props.updateFormDisplayHandler(true);
  };

  /**
   * block entity
   */
  const blockEntity = () => {
    api.call('benefit_types.change_status', { id: props.id, status: 'blocked' }).then(() => {
      afterSubmit();
    });
  };

  /**
   * activate
   */
  const activateEntity = () => {
    api.call('benefit_types.change_status', { id: props.id, status: 'active' }).then(() => {
      afterSubmit();
    });
  };

  /**
   * actions
   * @returns
   */
  const getMenu = () => {
    const out = [];
    const updateRight = user.haveRight('benefit_types_check.update');

    if (updateRight) {
      out.push({ text: 'Редактировать', func: editEntity });
      //out.push({ text: 'Удалить', func: delEntity });
      if (props.status === 'active') {
        out.push({ text: 'Заблокировать', func: blockEntity });
      } else {
        out.push({ text: 'Активировать', func: activateEntity });
      }
    }

    return out;
  };

  const menu = getMenu();

  return (
    <GridRow>
      <Cell>{data.getDictValue('benefit_types.status', props.status)}</Cell>
      <Cell>{props.name}</Cell>
      <Cell>{getDataField(props.data, 'fn')}</Cell>
      <Cell>{getDataField(props.data, 'min_amount', true)}</Cell>
      <Cell>{getDataField(props.data, 'amount', true)}</Cell>
      <OptionsCell disabled={props.formIsShow || !menu.length} options={menu} left={-66} />
    </GridRow>
  );
};

export default Row;
