import React from 'react';

import { user } from 'services';
import { GridRow } from 'libs/ui';
import { Cell } from 'libs/ui';
import { OptionsCell } from 'libs/ui';

const Row = (props) => {
  const editEntity = () => {
    props.setItemInfo({
      id: props.id,
      name: props.name,
      permissions: props.permissions,
    });
    props.updateFormDisplayHandler(true);
  };

  const changePermissions = () => {
    props.setItemInfo({
      id: props.id,
      name: props.name,
      permissions: props.permissions,
    });
    props.updatePermDisplayHandler(true);
    //console.log('🚀 ~ show perm');
  };

  /*
    const delEntity = () => {
        props.delItem(props.id, () => {
            props.getEntries({offset: 0}, null, true); // 
        });
    } 
    */

  const getMenu = () => {
    const out = [];
    if (user.haveRight('roles.update')) {
      out.push({ text: 'Переименовать', func: editEntity });
    }
    if (user.haveRight('roles.update')) {
      out.push({ text: 'Разрешения', func: changePermissions });
    }

    return out;
  };

  const menu = getMenu();

  return (
    <GridRow status="active">
      <Cell>{props.name}</Cell>
      <OptionsCell disabled={props.formIsShow || !menu.length} options={menu} left={-66} />
    </GridRow>
  );
};

export default Row;
