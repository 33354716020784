import RulesForm from './rules-form';
import { RulesWrapper } from './index.styled';

export const Rules = () => {
  return (
    <RulesWrapper>
      <RulesForm />
    </RulesWrapper>
  );
};

export default Rules;
