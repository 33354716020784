import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import moment from 'moment';

import { DateSelect, DictFilter, ParkingFilter, Container, Input, Select, DoubleField, DateTime, ButtonsContainer } from 'libs/form';
import { ResetBtn, UseBtn, Loader2 } from 'libs/ui';

class Filter extends Component {
  constructor(props) {
    super(props);

    // initial
    this.state = { terminals: props.apps };
    this.props.setInitial(initialValues);
  }

  /**
   * get app list
   * @returns
   */
  getTerminalOptions = () => {
    //isExtendedAppList
    return this.state.terminals.map((rack) => {
      return (
        <option key={rack.id} value={rack.id}>
          {rack.name}
        </option>
      );
    });
  };

  render() {
    const { handleSubmit, reset, change } = this.props;
    if (!this.state.terminals) {
      return <Loader2 />;
    }

    return (
      <Container>
        <form onSubmit={handleSubmit}>
          <DateSelect from_name="ts_from" to_name="ts_to" change={change}>
            <DoubleField>
              <DateTime width="160px" name="ts_from" type="dateTime" label="Период" withoutIcon />
              <DateTime width="160px" name="ts_to" type="dateTime" withoutIcon />
            </DoubleField>
          </DateSelect>

          <DoubleField>
            <Input width="80px" name="amount_from" type="text" label="Сумма" placeholder="От" naturalNumber />
            <Input width="80px" name="amount_to" type="text" placeholder="До" naturalNumber />
          </DoubleField>

          <DictFilter w={110} dict="payment.payment_type" />

          <Select width="145px" name="payment_terminal" type="select" label="№ устройства">
            <option value="">Все</option>
            {this.getTerminalOptions()}
          </Select>

          <Input width="70px" name="check_number" type="text" label="Счет N" placeholder="89546" />

          <Input width="70px" name="shift" type="text" label="Смена" placeholder="89546" />

          <DoubleField>
            <DictFilter w={120} dict="payment.mean_type" />
            <Input width="140px" name="mean_number" type="text" label="СО" placeholder="1234567890" />
          </DoubleField>
          <ParkingFilter />

          <DictFilter w={110} dict="payment.success" />

          <ButtonsContainer>
            <ResetBtn onClick={reset} />
            <UseBtn />
          </ButtonsContainer>
        </form>
      </Container>
    );
  }
}

const initialValues = {
  //success: true,
  ts_from: moment().startOf('day').format(),
  ts_to: moment().endOf('day').format(),
};

export default reduxForm({
  form: 'cashboxOperationsFilter',
  destroyOnUnmount: false,
  initialValues: initialValues,
})(Filter);
