export const MeanStatus = (props) => {
  const { row } = props;

  const out = [];
  if (row.is_mean_blocked) {
    if (row.is_suspicious) {
      out.push(<img title="Подозрительный проезд" className="td-icon" key="ms1" src="/images/red_lock_icon.png" alt="" />);
    } else {
      out.push(<img title="Заблокированный проезд" className="td-icon" key="ms2" src="/images/black_lock_icon.png" alt="" />);
    }
  }
  if (row.is_handicapped) {
    out.push(<img title="Льгота" className="td-icon td-icon--big" key="ms3" src="/images/tax-free.png" alt="" />);
  }

  return !out.length ? null : out;
};
