import React, { Component } from 'react';
import { withRouter } from 'react-router';

import { user } from 'services';
import { Total, Button, themes, ButtonContainer, TableHeader, GridBox, Grid, TableHead, TableHeadRow, TopRow, withPopup } from 'libs/ui';
import Client from './Row';
import { routes } from 'router';

const CreateClientButton = withPopup((props) => {
  if (!user.haveRight('clients.add')) {
    return null;
  }

  return (
    <Button onClick={props.popupDisplayHandler} theme={themes.blue}>
      <img src="/images/shape.png" alt="" />
      Создать клиента
    </Button>
  );
});

class ClientsGrid extends Component {
  state = { showCreateClientMenu: false };

  getClientsList = () => {
    const clients = this.props.entries || this.props.clients;
    let clientsList = [];
    if (clients) {
      for (let key in clients) {
        clientsList.push(<Client key={clients[key].id} groups={this.props.groups} {...clients[key]} />);
      }
    }
    return clientsList;
  };

  showNewClientPage = (type) => {
    this.props.setTypeOfNewClient(type);
    this.props.history.push(routes.newClient);
  };

  render() {
    return (
      <GridBox>
        {this.props.hideTotal ? null : (
          <TopRow>
            <Total cnt={this.props.count} />
            <ButtonContainer space="18px">
              <CreateClientButton
                options={[
                  { text: 'Физ. лицо', func: () => this.showNewClientPage('natural_person') },
                  { text: 'ИП', func: () => this.showNewClientPage('sole_proprietor') },
                  { text: 'Юр. лицо', func: () => this.showNewClientPage('legal_person') },
                ]}
              />
              {this.props.getXlsLink()}
            </ButtonContainer>
          </TopRow>
        )}
        <Grid className={this.props.className}>
          <TableHead>
            <TableHeadRow>
              <TableHeader width="100px" sortField="id" numeric>
                ID
              </TableHeader>
              <TableHeader width="115px">Статус</TableHeader>
              <TableHeader width="100px">Тип</TableHeader>
              <TableHeader sortField="name">Наименование</TableHeader>
              <TableHeader width="215px">Контактное лицо</TableHeader>
              <TableHeader width="140px">Телефон</TableHeader>
            </TableHeadRow>
          </TableHead>
          <tbody>{this.getClientsList()}</tbody>
        </Grid>
      </GridBox>
    );
  }
}

export default withRouter(ClientsGrid);
