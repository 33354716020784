import { data } from 'services';
import { getCategoryById, getVehicleClassById, getDate } from 'services/types';
import { ParkingCell } from 'libs/ui';

// get row class
const getRowClass = (row) => {
  let out = '';
  out += ' ' + row.status;
  return out;
};

const getFields = (props) => {
  const fields = [
    { name: 'is_blocked', title: 'Статус', width: 155, sortField: 'status', render: (val) => data.getDictValue('payment_mean.status', val) },
    { name: 'vehicle_plate', title: 'ГРЗ', width: 170, sortField: 'vehicle_plate' },
    { name: 'pan', title: 'БСК', width: 120, sortField: 'pan' },
    { name: 'name', title: 'Держатель', sortField: 'name' },
    { name: 'category_id', title: 'Категория', width: 185, sortField: 'category_id', render: (val) => getCategoryById(val) },
    { name: 'vehicle_class', title: 'Класс ТС', width: 160, sortField: 'vehicle_class', render: (val) => getVehicleClassById(val) },
    { name: 'start_at', title: 'Дата с', width: 210, sortField: 'start_at', render: (val) => getDate(val) },
    { name: 'end_at', title: 'Дата по', width: 210, sortField: 'end_at', render: (val) => getDate(val) },
    { name: 'comment', title: 'Комментарий', width: 205 },
  ];

  if (window.config.central) {
    fields.unshift({ name: 'parking_id', title: 'Парковка', render: (val) => <ParkingCell parking_id={val} cmp={'span'} />, width: 140 });
  }

  if (!props.clientId) {
    fields.push({ name: 'client_name', title: 'Клиент', width: 145 });
  }
  return fields;
};

export const tableParams = { getFields, getRowClass, withComment: true };
