import React from 'react';
import { withRouter } from 'react-router-dom';

import { user, data } from 'services';
import { routes } from 'router';
import { getAmount } from 'libs/common';
import { Cell, GridRow, ZoneIcon } from 'libs/ui';
import { getDuration, getMeanStatus, getDateTime, getParking, getShortRFID } from 'services/types';
import { ZoneIconsBox, ZoneListItem } from './Row.styled';

class Stay extends React.Component {
  showCard = () => {
    if (this.props.disableClick === true) {
      return;
    }
    this.props.history.push(`${routes.stay}?id=${this.props.id}`);
  };

  getZonesIcons = () => {
    if (this.props.stay_zones && this.props.zones) {
      const zones = this.props.zones.getZones();
      const stayZones = this.props.stay_zones;
      const stayIcons = [];
      if (stayZones && stayZones.length) {
        stayZones.forEach((item, index) => {
          if (item) {
            for (let i = 0; i < zones.length; i++) {
              if (stayIcons.length > 3) break;

              if (item === zones[i].id) {
                stayIcons.push(
                  <ZoneListItem key={index}>
                    <ZoneIcon color={zones[i].color}>{zones[i].name}</ZoneIcon>
                  </ZoneListItem>
                );
                //}
              }
            }
          }
        });
      }
      return <ZoneIconsBox>{stayIcons}</ZoneIconsBox>;
    }
  };

  render() {
    const {
      amount,
      mean_type,
      mean_number,
      parking_id,
      is_active,
      is_suspicious,
      client_name,
      tariff_id,
      tariff_name,
      entry_at,
      duration,
      leave_at,
      vehicle_plate,
    } = this.props;

    const parking = getParking(parking_id);
    const rightView = user.haveRight('stays.view');
    const meanNumber = mean_type === 'rfid' ? getShortRFID(mean_number) : mean_number;

    return (
      <GridRow $is_suspicious={is_suspicious} status={is_active ? 'active' : 'archive'} onClick={rightView ? this.showCard : null}>
        {window.config.central && <Cell>{parking}</Cell>}
        <Cell>{this.getZonesIcons()}</Cell>
        <Cell>{data.getDictValue('stays.status', is_active)}</Cell>
        <Cell>{client_name}</Cell>
        <Cell>
          <a href={'/card/tariff?id=' + tariff_id} target="_blank" rel="noopener">
            {tariff_name}
          </a>
        </Cell>
        <Cell>{getDateTime(entry_at)}</Cell>
        <Cell clip number>
          {getDuration(duration)}
        </Cell>
        <Cell>{getDateTime(leave_at)}</Cell>
        <Cell clip>{vehicle_plate}</Cell>
        <Cell clip>{data.getDictValue('stays.mean_type', mean_type)}</Cell>
        <Cell>{getMeanStatus(this.props)}</Cell>
        <Cell clip>{meanNumber}</Cell>
        <Cell clip number>
          {getAmount(amount)}
        </Cell>
      </GridRow>
    );
  }
}

export default withRouter(Stay);
