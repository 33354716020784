import PropTypes from 'prop-types';

//import { OptionsCell } from 'libs/ui';
import TableHeaderField from './table-header-field';
//import TableHeaderAction from './table-header-action';
import { TableHead, TableRow } from './table.header.styled';

export const TableHeader = (props) => {
  const { fields, actions, theme } = props;

  return (
    <TableHead>
      <TableRow>
        {fields.map((field, idx) => (
          <TableHeaderField key={idx} {...field} theme={theme} />
        ))}
        {actions && actions.length > 0 && <TableHeaderField key={'table-actions'} width={80} title="Действия" theme={theme} />}
        {/*withDelete && <TableHeaderAction key={'del'} title="" />*/}
      </TableRow>
    </TableHead>
  );
};

TableHeader.propTypes = {
  fields: PropTypes.array.isRequired,
  actions: PropTypes.array,
};

export default TableHeader;
