import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const Container = styled.div`
  margin-right: ${(props) => (props.$lastField ? '0' : '20px')};
  div {
    margin: 0;
  }
`;
const CalendarInterval = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: space-around;
  p {
    cursor: pointer;
    color: #3ea5ff;
    font-size: 13px;
  }
  p:hover {
    color: #6200ff;
  }
  ${(props) =>
    props.black &&
    css`
      p:hover {
        color: #fff;
      }
    `}
`;

export const CalInterval = ({ setPeriod, black }) => {
  // console.log('🚀 ~ CalInterval ~ black:', black);
  const { t } = useTranslation();
  return (
    <CalendarInterval className="calendar-intervals" black={black}>
      <p onClick={() => setPeriod('today')}>{t('grid.Сегодня')}</p>
      <p onClick={() => setPeriod('currentWeek')}>{t('grid.7 дней')}</p>
      <p onClick={() => setPeriod('currentMonth')}>{t('grid.30 дней')}</p>
      <p onClick={() => setPeriod('lastThreeMonths')}>{t('grid.3 месяца')}</p>
      <p onClick={() => setPeriod('')}>{t('grid.Весь период')}</p>
    </CalendarInterval>
  );
};

export const DateSelect = (props) => {
  const setPeriod = (period) => {
    let date = moment(); //new Date();
    let firstDay = null;
    let lastDay = null;
    const today = moment(date).set({ hour: 23, minute: 59, second: 0, millisecond: 0 }); //new Date(date.setHours(23, 59, 59, 0));
    const today2 = moment(date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }); //new Date(date.setHours(0, 0, 0, 0));
    switch (period) {
      case 'currentMonth':
        firstDay = moment(today2).subtract(29, 'days');
        lastDay = today;
        break;
      case 'currentWeek':
        firstDay = moment(today2).subtract(6, 'days');
        lastDay = today;
        break;
      case 'lastThreeMonths':
        firstDay = moment(today2).subtract(89, 'days'); //new Date(today2.setDate(today2.getDate() - 89));
        lastDay = today;
        break;
      case 'today':
        firstDay = today2;
        lastDay = today;
        break;
      default:
        lastDay = '';
        firstDay = '';
    }
    props.change(props.from_name, firstDay);
    //console.log('🚀 ~ setPeriod ~ firstDay', firstDay);
    props.change(props.to_name, lastDay);
    //console.log('🚀 ~ setPeriod ~ lastDay', lastDay);
  };

  return (
    <Container $lastField={props.lastField}>
      {props.children}
      <CalInterval setPeriod={setPeriod} black={props.black} />
    </Container>
  );
};
