import styled, { css } from 'styled-components';

export const Back = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.55);
`;

export const Header = styled.div`
  padding: 15px 20px 20px;

  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #262b31;
`;

export const Wrapper = styled.div`
  position: fixed;
  z-index: 999;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  color: #333;
  width: 600px;
  top: 30%;
  left: 50%;
  margin: 0 0 0 -300px;
  ${(props) =>
    props.theme === 'dark' &&
    css`
      background-color: #393e44;
      color: #fff;
      padding: 0;
      ${Header} {
        font-size: 16px;
        font-weight: normal;
        color: #fff;
        padding: 15px 20px;
      }
    `}
`;

export const Content = styled.div`
  padding: 0px 20px 20px;
`;

export const Footer = styled.div`
  padding: 20px 20px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  left: 20px;
  bottom: 20px;
`;
