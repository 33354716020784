import React from 'react';

import { TableHeader } from 'libs/ui'
import Row from './Row';
import InfoBlock from './InfoBlock';
import {
  GridBox,
  Grid,
  TableHead,
  TableHeadRow,
  TopRow,
} from 'libs/ui';
import { Total } from 'libs/ui';

class EventsGrid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      detail: false,
    };
  }

  setInfo = (...rest) => {
    this.setState({ detail: true });
    this.props.setEventInfo(...rest);
  };

  closeDetail = () => {
    this.setState({ detail: false });
  };

  getEntries = () => {
    const entries = this.props.entries || this.props.events;
    //console.log('entries', entries)
    let Rows = [];
    if (entries) {
      for (let key in entries) {
        Rows.push(<Row key={entries[key].id} setEventInfo={this.setInfo} {...entries[key]} />);
      }
    }
    return Rows;
  };

  render() {
    const { detail } = this.state;

    return (
      <GridBox>
        <TopRow>
          <Total cnt={this.props.count} />
          {this.props.getXlsLink()}
        </TopRow>
        <Grid>
          <TableHead>
            <TableHeadRow>
              {window.config.central && <TableHeader width="130px">Парковка</TableHeader>}
              <TableHeader width="210px">Время</TableHeader>
              <TableHeader width="120px">Источник</TableHeader>
              <TableHeader width="140px">№</TableHeader>
              <TableHeader width="140px">Приоритет</TableHeader>
              <TableHeader>Описание</TableHeader>
            </TableHeadRow>
          </TableHead>
          <tbody>{this.getEntries()}</tbody>
        </Grid>
        {detail && <InfoBlock close={this.closeDetail} info={this.props.actionInfo} />}
      </GridBox>
    );
  }
}

export default EventsGrid;
