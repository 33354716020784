import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import moment from 'moment';

import { DateSelect, Container, Input, Select, DoubleField, DateTime, ButtonsContainer } from 'libs/form';
import { ResetBtn, UseBtn } from 'libs/ui';

class Filter extends Component {
  constructor(props) {
    super(props);

    // initial
    this.props.setInitial(initialValues);
  }

  render() {
    const { handleSubmit, reset, change } = this.props;
    const roles = Object.keys(window.config.role_map).map((idx) => {
      return (
        <option key={idx} value={idx}>
          {window.config.role_map[idx]}
        </option>
      );
    });
    roles.splice(
      0,
      0,
      <option key="00" value="">
        Все
      </option>
    );

    return (
      <Container /*height='160px'*/>
        <form onSubmit={handleSubmit}>
          <Select width="160px" name="role_id" type="select" label="Пользователь">
            {roles}
          </Select>

          <Input width="290px" name="name" type="text" label="Имя" placeholder="Иванов И.И." />

          <DateSelect from_name="timestamp_from" to_name="timestamp_to" change={change}>
            <DoubleField>
              <DateTime width="160px" name="timestamp_from" type="dateTime" label="Период" withoutIcon />
              <DateTime width="160px" name="timestamp_to" type="dateTime" withoutIcon />
            </DoubleField>
          </DateSelect>

          <Input width="230px" name="action" type="text" label="Описание" placeholder="Введите название" lastField />

          <ButtonsContainer>
            <ResetBtn onClick={reset} />
            <UseBtn />
          </ButtonsContainer>
        </form>
      </Container>
    );
  }
}

const initialValues = {
  timestamp_from: moment().startOf('day').format(),
  timestamp_to: moment().endOf('day').format(),
};

export default reduxForm({
  form: 'actionsFilter',
  destroyOnUnmount: false,
  initialValues: initialValues,
})(Filter);
