import React, { Component } from 'react';

import { TableHeader, Total, Button, themes, GridBox, Grid, TableHead, TableHeadRow, TopRow, Right } from 'libs/ui';
import Stay from './Row';
import { NewTicketAction } from '../card/actions/new-ticket-action';

export default class StaysGrid extends Component {
  getStaysList = () => {
    const stays = this.props.entries || this.props.stays;
    let stayList = [];
    if (stays) {
      for (let key in stays) {
        stayList.push(
          <Stay
            disableClick={this.props.disableClick}
            key={stays[key].id}
            zones={this.props.zones}
            stay_zones={this.props.stay_zones}
            {...stays[key]}
          />
        );
      }
    }
    return stayList;
  };

  render() {
    return (
      <GridBox>
        {this.props.hideTotal ? null : (
          <TopRow>
            <Total cnt={this.props.count} />

            <Right>
              {this.props.getXlsLink()}

              <NewTicketAction withoutInfo />
            </Right>
          </TopRow>
        )}
        <Grid className={this.props.className}>
          <TableHead>
            <TableHeadRow>
              {window.config.central && <TableHeader width="140px">Парковка</TableHeader>}
              <TableHeader width="75px">Зона</TableHeader>
              <TableHeader width="100px">Статус</TableHeader>
              <TableHeader>Клиент</TableHeader>
              <TableHeader width="175px">Тариф</TableHeader>
              <TableHeader width="210px" sortField="entry_at">
                Начало
              </TableHeader>
              <TableHeader width="153px" sortField="duration">
                Длительность
              </TableHeader>
              <TableHeader width="210px" sortField="leave_at">
                Конец
              </TableHeader>
              <TableHeader width="130px" sortField="vehicle_plate">ГРЗ</TableHeader>
              <TableHeader width="136px">Тип СО</TableHeader>
              <TableHeader width="30px"></TableHeader>
              <TableHeader width="150">СО</TableHeader>
              <TableHeader width="80px" sortField="amount">
                Сумма
              </TableHeader>
            </TableHeadRow>
          </TableHead>
          <tbody>{this.getStaysList()}</tbody>
        </Grid>
      </GridBox>
    );
  }
}
