import React from 'react';
import { useTranslation } from 'react-i18next';

import { config } from 'config';
import { Button, Place } from './lang-selector.styled';
const langs = [
  { code: 'ru', title: 'Русский' },
  { code: 'kk', title: 'Қазақша' },
];

export const LangSelector = (props) => {
  const { i18n } = useTranslation();
  //console.log('🚀 ~ LangSelector ~ i18n.language', i18n.language);
  if (!config.use_lang) {
    return null;
  }

  const changeLang = (code) => {
    i18n.changeLanguage(code);
    localStorage.setItem('lang', code);
  };

  const buttons = langs.map((el, idx) => {
    let cl = i18n.language === el.code ? 'active' : '';
    if (props.flags) {
      cl += ' flag';
    }
    return (
      <Button className={cl} onClick={() => changeLang(el.code)} key={idx} title={el.title}>
        {props.flags ? <img src={'/images/flags/' + el.code + '.svg'} alt={el.code} /> : el.title}
      </Button>
    );
  });
  const cl2 = props.abs ? '' : 'noabs';

  return <Place className={cl2}>{buttons}</Place>;
};

LangSelector.defaultProps = {
  flags: false,
  abs: true,
};
