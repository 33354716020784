import { TableHeader, Total, GridBox, Grid, TableHead, TableHeadRow, TopRow } from 'libs/ui';
import Row from './Row';

const BenefitsGrid = (props) => {
  const getBenefitsList = () => {
    const benefits = props.entries || props.rides;
    let benefitsList = [];
    if (benefits) {
      for (let key in benefits) {
        benefitsList.push(<Row zones={props.zones} key={benefits[key].id} {...benefits[key]} />);
      }
    }
    return benefitsList;
  };

  return (
    <GridBox>
      <TopRow>
        <Total cnt={props.count} />
        {props.getXlsLink()}
      </TopRow>
      <Grid>
        <TableHead>
          <TableHeadRow>
            {window.config.central && <TableHeader width="90px">Парковка</TableHeader>}
            <TableHeader width="220px" sortField="created_at">
              Время
            </TableHeader>
            <TableHeader width="240px" sortField="name">
              Клиент
            </TableHeader>
            <TableHeader width="240px" sortField="fn">
              Номер ФН
            </TableHeader>
            <TableHeader sortField="amount">Сумма</TableHeader>
            <TableHeader width="220px" sortField="amount_used">
              Фактическая скидка
            </TableHeader>
            <TableHeader width="80px">Действия</TableHeader>
          </TableHeadRow>
        </TableHead>
        <tbody>{getBenefitsList()}</tbody>
      </Grid>
    </GridBox>
  );
};

export default BenefitsGrid;
