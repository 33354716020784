import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { user } from 'services';
import { Button, themes, ButtonContainer, ParkomatsSelect, Loader } from 'libs/ui';
import { ParkingFilter2 } from 'libs/form';
import { findElement } from 'libs/common';
import Warning from 'modules/components/warnings';
import { Popup2 } from 'libs/ui';
import { getPrintableRacks, getActiveZones } from 'services/types';
import { ErrorMsg } from 'pages/styled';
import { NewTicketInfoAdd, NewTicketContent, NewTicketHeader, NewTicketInfo, AttentionIcon, Line1 } from './new-ticket.styled';

export const NewTicket = (props) => {
  const { parkingId, close, action, withoutInfo } = props;
  //console.log('🚀 ~ NewTicket ~ withoutInfo:', withoutInfo);
  const [error, setError] = useState('');
  const [currentParkingId, setCurrentParkingId] = useState(
    parkingId ? '' : user.getMaps().parking[0] ? user.getMaps().parking[0].id : ''
  );
  const [parkomat, setParkomat] = useState('');
  const [confirm, setConfirm] = useState(false);
  const [working, setWorking] = useState(false);
  const [comment, setComment] = useState('Штраф');
  const [racks, setRacks] = useState([]);
  const [zones, setZones] = useState([]);
  const [zone, setZone] = useState('');

  const [penalty, setPenalty] = useState('0');
  const [penaltyValue, setPenaltyValue] = useState(0);

  /**
   * get parkomats
   */
  useEffect(() => {
    getPrintableRacks().then((data) => {
      if (Array.isArray(data)) {
        if (!withoutInfo) {
          data = data.filter((el) => el.parking_id === parkingId);
        }
        data.forEach((el) => (el.mapped_name = el.name));
        const def = data.length > 0 ? data[0].id : '';
        setRacks(data);
        setParkomat(def);
      } else {
        setRacks([]);
      }
    });

    if (withoutInfo) {
      getActiveZones().then((data) => {
        if (Array.isArray(data)) {
          if (!withoutInfo) {
            data = data.filter((el) => el.parking_id === parkingId);
          }
          data.forEach((el) => (el.mapped_name = el.name));
          const def = data.length > 0 ? data[0].id : '';
          setZones(data);
          setZone(def);
        } else {
          setZones([]);
        }
      });
    }
  }, [parkingId]);

  const showConfirm = () => {
    let value = parseInt(penalty);
    const withPenalty = value === 1000;
    if (withPenalty) {
      value = penaltyValue;
    }

    if (withPenalty && !value) {
      setError('Заполните поле Сумма');
      return;
    }

    setError('');
    setConfirm(true);
  };

  const selectParking = (val) => {
    setCurrentParkingId(val);
  };

  const selectParkomat = (val) => {
    setParkomat(val);
  };

  const selectZone = (val) => {
    setZone(val);
  };

  /**
   * get confirm title
   * @returns
   */
  const getConfirmTitle = () => {
    const rack = findElement(racks, parkomat);
    let confirmTitle = 'Будет напечатан билет на устройстве ' + (rack ? rack.mapped_name : '') + ' зоне ' + zone;
    if (parseInt(penalty)) {
      confirmTitle += ', с дополнительным начислением ' + comment + ' в сумме ' + penaltyValue + '₽';
    } else {
      confirmTitle += ', без дополнительных начислений';
    }
    return confirmTitle;
  };

  /**
   * print
   * @returns
   */
  const print = () => {
    if (working) {
      return;
    }

    let value = parseInt(penalty);
    const withPenalty = value === 1000;
    if (withPenalty) {
      value = penaltyValue;
    }

    if (withPenalty && !value) {
      setError('Заполните поле Сумма');
      return;
    }

    setWorking(true);
    action({ parkomat, value, comment, zone })
      .then(() => {
        setWorking(false);
        close();
      })
      .catch((data) => {
        setError(data.message);
        setWorking(false);
      });
  };

  if (!racks || !racks.length) {
    return (
      <Popup2 w={460}>
        <Loader height={'120px'} />
        <ButtonContainer space="20px" justifyContent={'center'} style={{ marginTop: '40px' }}>
          <Button fontSize="16px" onClick={close}>
            Отменить
          </Button>
        </ButtonContainer>
      </Popup2>
    );
  }

  const confirmTitle = getConfirmTitle();

  return (
    <>
      <Popup2 w={460}>
        <AttentionIcon src="/images/icon_!.png" alt="" />
        <NewTicketContent>
          <NewTicketHeader>Печать нового билета</NewTicketHeader>

          {error && (
            <ErrorMsg>
              <img src="/images/error-triangle-icon.png" alt="" />
              {error}
            </ErrorMsg>
          )}

          {!parkingId && <ParkingFilter2 simple onChange={selectParking} current={currentParkingId} />}

          <ParkomatsSelect racks={racks} onChange={selectParkomat} parkingId={currentParkingId} value={parkomat} />

          {withoutInfo && <ParkomatsSelect label="Зона" racks={zones} onChange={selectZone} parkingId={currentParkingId} value={zone} />}

          <form onChange={(e) => setPenalty(e.target.value)}>
            <NewTicketInfo>
              <input defaultChecked={true} type="radio" name="penalty" value="0" /> Без дополнительной услуги
            </NewTicketInfo>
            <NewTicketInfo>
              <input type="radio" name="penalty" value="1000" /> С дополнительной услугой
            </NewTicketInfo>
          </form>

          {penalty === '1000' && (
            <NewTicketInfoAdd>
              <Line1>
                <span className="l">Наименование</span>
                <input
                  min={0}
                  onChange={(e) => setComment(e.target.value)}
                  type="text"
                  name="penaltytype"
                  value={comment}
                  style={{ margin: 0, width: '120px' }}
                />
              </Line1>
              <Line1>
                <span className="l">Сумма</span>
                <input
                  style={{ margin: 0, width: '70px' }}
                  min={0}
                  onChange={(e) => setPenaltyValue(e.target.value)}
                  type="number"
                  name="penaltyvalue"
                  value={penaltyValue}
                />
                &nbsp;₽
              </Line1>
            </NewTicketInfoAdd>
          )}

          <ButtonContainer space="20px" justifyContent={'flex-start'} style={{ marginTop: '40px' }}>
            <Button disabled={working} fontSize="16px" onClick={close}>
              Отменить
            </Button>
            <Button disabled={working} theme={themes.blue} fontSize="16px" onClick={showConfirm}>
              Печатать
            </Button>
          </ButtonContainer>
        </NewTicketContent>
      </Popup2>

      {confirm && (
        <Warning
          top={27}
          width="480px"
          header={confirmTitle}
          cancelButton={{
            func: () => setConfirm(false),
            text: 'Отменить',
          }}
          acceptButton={{
            func: () => {
              print();
            },
            text: 'Подтвердить',
            theme: themes.blue,
          }}
        />
      )}
    </>
  );
};

NewTicket.propTypes = {
  id: PropTypes.any.isRequired,
  parkingId: PropTypes.any,
  close: PropTypes.any.isRequired,
  action: PropTypes.any.isRequired,
  withoutInfo: PropTypes.bool,
};
