import styled from 'styled-components';

export const RulesWrapper = styled.div`
  width: 1300px;
  min-height: 920px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 0 0 1px rgba(85, 85, 85, 0.2);
  padding-bottom: 40px;
`;
