import React, { Component } from 'react';

import { TableHeader } from 'libs/ui'
import Row from './Row';
import {
  GridBox,
  Grid,
  TableHead,
  TableHeadRow,
  TopRow,
} from 'libs/ui';
import { Total } from 'libs/ui';

export default class BenefitsGrid extends Component {
  getBenefitsList = () => {
    const benefits = this.props.entries || this.props.rides;
    let benefitsList = [];
    if (benefits) {
      for (let key in benefits) {
        benefitsList.push(<Row zones={this.props.zones} key={benefits[key].id} {...benefits[key]} />);
      }
    }
    return benefitsList;
  };

  render() {
    return (
      <GridBox>
        <TopRow>
          <Total cnt={this.props.count} />
          {this.props.getXlsLink()}
        </TopRow>
        <Grid>
          <TableHead>
            <TableHeadRow>
              {window.config.central && <TableHeader width="90px">Парковка</TableHeader>}
              <TableHeader width="220px" sortField="timestamp">
                Время
              </TableHeader>
              <TableHeader width="140px">Билет</TableHeader>
              <TableHeader>Госномер</TableHeader>
              <TableHeader width="80px">Действия</TableHeader>
            </TableHeadRow>
          </TableHead>
          <tbody>{this.getBenefitsList()}</tbody>
        </Grid>
      </GridBox>
    );
  }
}
