import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import styled from 'styled-components';
import moment from 'moment';

import { DateSelect } from 'libs/form';
import { ParkingFilter } from 'libs/form';
import {
  Container,
  Input,
  //Multiselect,
  //Select,
  DoubleField,
  DateTime,
  //Toggle,
  ButtonsContainer,
} from 'libs/form';
import { ResetBtn } from 'libs/ui';
import { UseBtn } from 'libs/ui';

const StyledContainer = styled(Container)`
  form {
  }
`;

class Filter extends Component {
  constructor(props) {
    super(props);

    // initial
    this.props.setInitial(initialValues);
  }

  render() {
    const { handleSubmit, reset, change } = this.props;

    return (
      <StyledContainer /*height='200px'*/>
        <form onSubmit={handleSubmit}>
          <DateSelect from_name="datetime_from" to_name="datetime_to" change={change}>
            <DoubleField>
              <DateTime width="160px" name="datetime_from" type="dateTime" withoutIcon label="Время" />
              <DateTime width="160px" name="datetime_to" type="dateTime" withoutIcon />
            </DoubleField>
          </DateSelect>

          <Input width="220px" name="mean_id" type="text" label="Билет" placeholder="Билет" />

          <Input width="150px" name="vehicle_plate" type="text" label="Госномер" placeholder="А000AA 000 rus" />

          <ParkingFilter />

          <ButtonsContainer>
            <ResetBtn onClick={reset} />
            <UseBtn />
          </ButtonsContainer>
        </form>
      </StyledContainer>
    );
  }
}

const initialValues = {
  datetime_from: moment().startOf('day').format(),
  datetime_to: moment().endOf('day').format(),
};

export default reduxForm({
  form: 'benefitFilter',
  destroyOnUnmount: false,
  initialValues: initialValues,
})(Filter);
