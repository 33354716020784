import React from 'react';
//import styled from 'styled-components'

import { GridContainer } from 'libs/ui';
import Filter from './Filter';

function AdditionalReports() {
  return (
    <GridContainer>
      <Filter />
    </GridContainer>
  );
}

export default AdditionalReports;
