import { GridContainer } from 'libs/ui';
import { Subscription } from 'grids/subscription/subscription';

const SubscriptionWrapper = (props) => {
  return (
    <GridContainer>
      <Subscription {...props}  />
    </GridContainer>
  );
};

export default SubscriptionWrapper;
