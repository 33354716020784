import React from 'react';
import styled from 'styled-components';

import { StyledField, DarkMultiselect } from 'libs/form';
import { required, MultiselectView } from 'libs/form';
import { ParkingFilter2 } from 'libs/form';
import { SectionTitle } from './tariff-form-main.styled';

const TypeSelect = styled(StyledField)`
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
  }
`;

function TariffForm({ vehicleClasses, categories }) {
  return (
    <>
      <TypeSelect type="select" label="Тип" name="type" disabled={true}>
        <option value="individual">Индивидуальный</option>
      </TypeSelect>
      <ParkingFilter2 dark />
      <StyledField validate={required} placeholder="Осень 2017" type="text" label="Название *" name="name" />
      <DarkMultiselect
        validate={required}
        type="multiselect"
        label="Класс ТС *"
        name="vehicle_class"
        placeholder="Выберите класс ТС"
        data={vehicleClasses}
        additionalComponent={MultiselectView}
      />
      <DarkMultiselect
        // validate={required}
        type="multiselect"
        label="Категория ТС"
        name="vehicle_category"
        placeholder="Выберите категорию ТС"
        data={categories}
        additionalComponent={MultiselectView}
      />
      <StyledField className="shortfield" validate={required} placeholder="08 авг 2017" type="dateTime" label="Начало *" name="time_from" />
      <StyledField type="text" placeholder="15 мин" label="Время на выезд" name="time_to_leave" mask={{ suffix: ' мин' }} />

      <SectionTitle>Абонемент</SectionTitle>
      <StyledField type="text" placeholder="Цена абонемента" label="Цена абонемента" name="subscription_amount" mask={{ suffix: ' руб' }} />
      <StyledField type="text" placeholder="30 дней" label="Срок действия" name="subscription_period" mask={{ suffix: ' дней' }} />

      <>
        <SectionTitle>Повторный въезд</SectionTitle>
        <StyledField type="text" placeholder="15 мин" label="Повторный платный въезд" name="secondentry_interval" mask={{ suffix: ' мин' }} />
      </>
    </>
  );
}

export default TariffForm;
