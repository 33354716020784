import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import styled from 'styled-components';

import { required, WhiteStyledField } from 'libs/form';
import { Button, themes, ButtonContainer } from 'libs/ui';

const StyledButtonContainer = styled(ButtonContainer)`
  margin-top: 20px;
`;

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      role: '',
    };
  }

  submit = (values) => {
    //values.password = values.username;
    let decorateValues = { ...values };
    if (!decorateValues.password) {
      decorateValues.password = values.username;
    }

    if (decorateValues.role_id) {
      decorateValues.role_id = Number(decorateValues.role_id);
    } else {
      decorateValues.role_id = Number(Object.keys(window.config.role_map)[0]);
    }

    return this.props.addEntity(decorateValues, this.afterSubmit).catch((errors) => {
      console.log(errors);
      if (errors && errors.data && errors.data.type === 'duplicate') {
        throw new SubmissionError({
          username: 'duplicate',
        });
      }
    });
  };

  afterSubmit = () => {
    this.props.getEntries({ offset: 0 });
    this.props.updateMessageDisplayHandler(true);
    setTimeout(() => {
      this.props.updateMessageDisplayHandler(false);
    }, 3000);
    this.props.displayHandler(false);
    this.props.destroy(this.props.form);
  };

  changeRole = (data) => {
    const el = data.target;
    if (el.name === 'role_id') {
      this.setState({ role: el.value });
    }

    //console.log(data.target);
  };

  //console.log('render');

  render() {
    const { role } = this.state;
    const incassator = role === '2' || role === '4';
    const roles = Object.keys(window.config.role_map).map((idx) => {
      return (
        <option key={idx} value={idx}>
          {window.config.role_map[idx]}
        </option>
      );
    });

    return (
      <form onSubmit={this.props.handleSubmit(this.submit)} onChange={this.changeRole}>
        <WhiteStyledField
          type="select"
          label="Роль"
          name="role_id"
          //onChange={this.changeRole}
        >
          {roles}
        </WhiteStyledField>
        <WhiteStyledField
          type="text"
          label={incassator ? 'Номер БСК' : 'Логин'}
          placeholder={incassator ? 'Номер БСК' : 'Логин'}
          name="username"
          validate={required}
        />
        <WhiteStyledField type="text" label="ФИО" placeholder="Иванов Иван Иванович" name="name" validate={required} />
        <WhiteStyledField
          type="text"
          label="Пароль"
          placeholder="password"
          name="password"
          className={incassator ? 'hidden' : ''}
          validate={incassator ? null : required}
        />
        <WhiteStyledField type="text" label="E-mail" placeholder="someone@gmail.com" name="email" />
        <WhiteStyledField type="text" label="Телефон" placeholder="+7 956 456-56-78" name="phone" />
        <StyledButtonContainer space="20px">
          <Button
            type="button"
            onClick={() => {
              this.props.displayHandler(false);
              this.props.destroy(this.props.form);
            }}
          >
            Отменить
          </Button>
          <Button theme={themes.blue}>
            <i className="fa fa-save" />
            Сохранить
          </Button>
        </StyledButtonContainer>
      </form>
    );
  }
}

Form = reduxForm()(Form);

Form = connect((store, props) => {
  //const values = (store.form && store.form.addUserForm) ? store.form.addUserForm.values : null;
  //console.log(values);
  return {
    //values: values,
    form: 'addUserForm',
    destroyOnUnmount: false,
    initialValues: props.initialValues,
  };
})(Form);

export default Form;
