import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
// import styled from 'styled-components';
import moment from 'moment';

import rackList from 'services/rackList';
import zones from 'services/zones';
import { DateSelect } from 'libs/form';
import { Container, Input, Multiselect, DoubleField, DateTime, ButtonsContainer, ZoneInput } from 'libs/form';
import { ParkingFilter } from 'libs/form';
import { UseBtn } from 'libs/ui';
import { ResetBtn } from 'libs/ui';

import { DictFilter } from 'libs/form';

/*
const StyledZoneInput = styled(ZoneInput)`
  & > .popup {
    padding: 10px;
  }
`;
*/

class Filter extends Component {
  constructor(props) {
    super(props);

    // initial
    this.props.setInitial(initialValues);
  }

  render() {
    const { handleSubmit, reset, change } = this.props;

    return (
      <Container>
        <form onSubmit={handleSubmit}>
          <DateSelect from_name="datetime_from" to_name="datetime_to" change={change}>
            <DoubleField>
              <DateTime width="160px" name="datetime_from" type="dateTime" withoutIcon label="Время" />
              <DateTime width="160px" name="datetime_to" type="dateTime" withoutIcon />
            </DoubleField>
          </DateSelect>

          <DictFilter w={150} name="result" dict="passage.result" />

          <DictFilter w={150} name="type" dict="passage.type" />

          <Multiselect
            width="150px"
            name="zones_from"
            type="multiselect"
            label="Зона из"
            additionalComponent={ZoneInput}
            data={zones.getZones()}
            countDisplayedIcons={1}
          />

          <Multiselect width="180px" name="rack_ids" label="Узел/стойка" type="rackSelect" data={rackList.getItems()} />

          <Multiselect
            width="150px"
            name="zones_to"
            type="multiselect"
            label="Зона в"
            additionalComponent={ZoneInput}
            data={zones.getZones()}
            countDisplayedIcons={1}
          />

          <DictFilter w={150} dict="vehicle_class" />

          <Input width="130px" name="vehicle_plate" type="text" label="Госномер" placeholder="А000AA 000 rus" />

          <DictFilter w={180} name="list" dict="passage.list" />

          <Input width="220px" name="csc_owner" type="text" label="Держатель" placeholder="Введите текст для поиска" />

          <ParkingFilter />

          <ButtonsContainer className="center">
            <ResetBtn onClick={reset} />
            <UseBtn />
          </ButtonsContainer>
        </form>
      </Container>
    );
  }
}

const initialValues = {
  datetime_from: moment().startOf('day').format(),
  datetime_to: moment().endOf('day').format(),
  mean_type: 'vehicle_plate',
};

export default reduxForm({
  form: 'plateRidesFilter',
  destroyOnUnmount: false,
  initialValues: initialValues,
})(Filter);
