import React, { Component } from 'react';
import styled from 'styled-components';

import { api } from 'api';
import zones from 'services/zones';
import { notify } from 'libs/common';
import { Button, themes } from 'libs/ui';

const RfidBox = styled.div`
  position: relative;
  color: #fff;
`;

const ZoneIcon = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  line-height: 32px;
  border: 2px solid #fff;
  font-size: 13px;
  color: white;
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
`;
const Zone = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
`;
const ZoneTitle = styled.div`
  margin: 0 40px 0 10px;
`;
const ZoneInput = styled.div`
  display: flex;
  align-items: center;
  label {
    margin-right: 10px;
    font-size: 14px;
    color: #eee;
    white-space: nowrap;
  }
  input {
    max-width: 100%;
    font-size: 15px;
    color: #000;
    width: 150px;
    padding: 11px 10px 9px;
    border-radius: 4px;
    border: solid 1px #bebebe;
    height: 30px;
  }
`;
const Buttons = styled.div`
  margin: 30px 0 0;
`;

class ZonesLimit extends Component {
  state = {
    ready: false,
    limits: [],
  };

  componentDidMount() {
    this.getClientLimits();
  }

  getClientLimits = () => {
    api.call('clients.get_limits', { client_id: this.props.clientId }).then((data) => {
      //console.log('🚀 ~ api ~ data', data);
      const colors = zones.getZonesColors();
      const limits = data.map((el) => {
        return { ...el, color: colors[el.zone_id] };
      });

      this.setState({ ready: true, limits: limits });
    });
  };

  change = (idx, event) => {
    const out = this.state.limits.splice(0);
    let val = parseInt(event.target.value);
    if (val > 9999) {
      val = 9999;
    }
    out[idx].limit = val;
    //console.log('🚀 ~ out', out);
    this.setState({ limits: out });
  };

  handleFocus = (event) => {
    event.target.select();
  };

  getZones = () => {
    const { limits } = this.state;

    return limits.map((el, idx) => {
      return (
        <Zone key={idx}>
          <ZoneIcon color={el.color} />
          <ZoneTitle>{el.zone_id}</ZoneTitle>
          <ZoneInput>
            <label htmlFor={idx}>Лимит мест</label>
            <input
              min={0}
              max={9999}
              onFocus={this.handleFocus}
              id={idx}
              type="number"
              name={idx}
              value={el.limit}
              onChange={(event) => this.change(idx, event)}
            />
          </ZoneInput>
        </Zone>
      );
    });
  };

  save = () => {
    const out = this.state.limits.map((el) => {
      return { zone_id: el.zone_id, limit: el.limit };
    });
    //console.log('🚀 ~ out', out);
    api.call('clients.set_limits', { client_id: this.props.clientId, limits: out }).then((data) => {
      //console.log('🚀 ~ api ~ data', data);
      notify('Лимиты ТС', 'Лимиты сохранены', 'success');
      //this.setState({ success: true });
    });
  };

  render() {
    const { ready } = this.state;

    if (!ready) {
      return null;
    }
    //console.log('🚀 ~ render ~ limits', limits);

    const zones = this.getZones();

    return (
      <RfidBox>
        {zones}
        {zones.length === 0 ? (
          <p>Данные по лимитам зон у клиента отсутствуют</p>
        ) : (
          <Buttons>
            <Button theme={themes.gray} onClick={this.save}>
              <i className="fa fa-save" />
              Сохранить
            </Button>
          </Buttons>
        )}
      </RfidBox>
    );
  }
}
export default ZonesLimit;
