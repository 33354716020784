import React from 'react';

import { TableHeader } from 'libs/ui'
import Row from './Row';
import InfoBlock from './InfoBlock';
import {
  GridBox,
  Grid as StyledGird,
  TableHead,
  TableHeadRow,
  TopRow,
} from 'libs/ui';
import { Total } from 'libs/ui';

class Grid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      detail: false,
    };
  }

  setInfo = (...rest) => {
    this.setState({ detail: true });
    this.props.setActionInfo(...rest);
  };

  closeDetail = () => {
    this.setState({ detail: false });
  };

  getEntries = () => {
    const entries = this.props.entries || this.props.actions;

    let Rows = [];
    if (entries) {
      for (let key in entries) {
        Rows.push(<Row key={entries[key].id} {...entries[key]} setActionInfo={this.setInfo} />);
      }
    }
    return Rows;
  };

  render() {
    const { detail } = this.state;

    return (
      <GridBox>
        <TopRow>
          <Total cnt={this.props.count} />
          {this.props.getXlsLink()}
        </TopRow>
        <StyledGird>
          <TableHead>
            <TableHeadRow>
              <TableHeader width="230px">Пользователь</TableHeader>
              <TableHeader width="230px">Имя</TableHeader>
              <TableHeader width="210px">Время</TableHeader>
              <TableHeader>Описание</TableHeader>
              <TableHeader width="70px"></TableHeader>
            </TableHeadRow>
          </TableHead>
          <tbody>{this.getEntries()}</tbody>
        </StyledGird>
        {detail && <InfoBlock close={this.closeDetail} info={this.props.actionInfo} />}
      </GridBox>
    );
  }
}

export default Grid;
