import styled from 'styled-components';

import { Button, ButtonContainer } from 'libs/ui';
import { StyledGrid } from 'modules/components/card/styledComponents';
import StaysGrid from '../grid/Grid';

export const Hover = styled.p``;

export const ButtonContainer2 = styled(ButtonContainer)`
  flex-wrap: wrap;
  justify-content: flex-start;
  & > * {
    margin: 0 1rem 0.5rem 0;
  }
`;

export const AddtionalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 20px;
  padding: 0 10px;
  margin-bottom: 20px;
`;

export const ClientName = styled.p`
  font-size: 20px;
  color: #ffffff;
`;

export const StyledStaysGrid = styled(StyledGrid).attrs({ as: StaysGrid })``;

export const HandicappedBlock = styled.div`
  cursor: pointer;
  width: 173px;
  height: 50px;
  border-radius: 25px;
  background-color: #31363b;
  display: flex;
  align-items: flex-start;
  padding: 10px 5px 0 5px;
  box-sizing: border-box;
  color: white;
  font-size: 15px;
  position: relative;
  img {
    position: absolute;
    left: 10px;
    top: 50%;
    margin: -16px 0 0 0;
    width: 32px;
    height: auto;
  }
  p {
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &.free {
    height: 36px;
    width: 200px;
    padding: 10px 5px 0 45px;
    text-align: left;
    font-size: 13px;
  }
  &.free p {
    margin: -6px 0 0 0;
    text-align: left;
    max-width: none;
  }
  /*
  &:hover{
    width: auto;
  }
  &:hover p{
    max-width: none;
    overflow: visible;

  }
  */
`;

export const FreeButton = styled(Button)`
  position: relative;
  width: 130px;
  padding-left: 40px;
  img {
    position: absolute;
    left: 10px;
    top: 50%;
    margin: -16px 0 0 0;
    width: 32px;
    height: auto;
  }
`;

export const MeanTypeId = styled.div`
  display: flex;
  align-items: center;
`;

export const MeanId = styled.div`
  margin-left: 20px;
  //font-weight: bold;
  color: #fff;
  font-size: 20px;
`;
