import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import { user } from 'services';
import { getRules, toggleNode, toggleRack, toggleZone, updateRule } from 'store/ducks/models/rules';
import zonesService from 'services/zones';
//import { routes } from 'router';
import { PanelWrapper, Tabs, Tab } from 'modules/components/card/zoneTabs/styledComponents';
import { StyledButton, Zone, CheckboxWrapper, Checkbox } from './zones.styled';

class Zones extends React.Component {
  state = { selectedZone: 'P1', saving: false };

  componentDidMount() {
    this.setState({ selectedZone: this.props.selectedZoneId });
    this.props.getRules();
  }

  setZone = (id) => {
    this.setState({ selectedZone: id });
  };

  /**
   * get tabs
   * @returns tabs
   */
  getTabs = () => {
    const colors = zonesService.getZonesColors();
    const zones = zonesService.getZonesFromRule(this.props.rules);

    const tabs = zones.map((item) => {
      const id = item.zone_id;
      const title = id;
      const color = colors[id] ? colors[id] : '#4d8b8b';
      const zoneType = 'single';
      const isSelected = id === this.state.selectedZone;
      return (
        <Tab isSelected={isSelected} key={id} color={color} type={zoneType} onClick={() => this.setZone(id)}>
          {title}
        </Tab>
      );
    });

    return tabs;
  };

  /**
   * get zone by id
   * @param {*} zoneId
   * @returns
   */
  getZone(zoneId) {
    const rule = this.props.rulesForm.find((el) => el.rule_id === this.props.ruleId);
    let zone = rule.zones.find((item) => item.zone_id === zoneId);

    if (!zone) {
      zone = [];
    }
    return zone;
  }

  /**
   * get checks elements
   * @param {*} zoneId
   * @returns
   */
  getCheckboxes = (zoneId) => {
    const rightEdit = user.haveRight('rules.update');

    let zoneNodes = [];
    let zone = this.getZone(zoneId);
    let allRacks = true;
    if (zone.nodes) {
      zoneNodes = zone.nodes.map((node) => {
        let nodeChecked = true;
        let title = '';

        let racks = node.racks.map((rack) => {
          if (!rack.enabled) {
            nodeChecked = false;
            allRacks = false;
          }
          title = zonesService.getRackTitle(zone.zone_id, node.node_id, rack.rack_id);
          console.log('🚀 ~ zoneNodes=zone.nodes.map ~ checekd:', rack.enabled);
          return (
            <Checkbox color={zonesService.getColor(this.state.selectedZone)} className="checkbox" key={rack.rack_id}>
              <input
                onChange={() => {
                  if (rightEdit) {
                    this.props.toggleRack(rack.enabled, this.props.ruleId, zone.zone_id, node.node_id, rack.rack_id);
                  }
                }}
                name={'rack_' + rack.rack_id}
                checked={rack.enabled}
                type="checkbox"
              />
              <label>С{title}</label>
            </Checkbox>
          );
        });

        title = zonesService.getNodeTitle(zone.zone_id, node.node_id);
        return (
          <div style={{ display: 'flex' }} key={node.node_id}>
            <Checkbox color={zonesService.getColor(this.state.selectedZone)} className="checkbox">
              <input
                onChange={() => {
                  if (rightEdit) {
                    this.props.toggleNode(nodeChecked, this.props.ruleId, zone.zone_id, node.node_id);
                  }
                }}
                checked={nodeChecked}
                type="checkbox"
              />
              <label>У{title}</label>
            </Checkbox>
            {racks}
          </div>
        );
      });
    }

    return (
      <CheckboxWrapper>
        <Checkbox color={zonesService.getColor(this.state.selectedZone)} className="checkbox">
          <input
            onChange={() => {
              if (rightEdit) {
                this.props.toggleZone(allRacks, this.props.ruleId, zone.zone_id);
              }
            }}
            checked={allRacks}
            type="checkbox"
          />
          <label>Все стойки</label>
        </Checkbox>

        {zoneNodes}
      </CheckboxWrapper>
    );
  };

  /**
   * update rule
   */
  updateRule = () => {
    if (this.state.saving) {
      return;
    }
    this.setState({ saving: true });
    this.props.updateRule(this.props.ruleId, () => {
      this.props.refresh();
    });
  };

  /**
   * render
   */
  render() {
    const { selectedZone, saving } = this.state;
    const rightEdit = user.haveRight('rules.update');

    const tabs = this.getTabs();
    const color = zonesService.getColor(selectedZone);
    const checkboxes = this.getCheckboxes(selectedZone);
    const cl = saving ? 'saving' : '';

    return (
      <PanelWrapper>
        <Tabs>{tabs}</Tabs>
        <Zone color={color}>
          <div>
            {checkboxes}
            <br />
          </div>
          {rightEdit && (
            <StyledButton className={cl} disabled={this.state.fieldsDisable} onClick={this.updateRule}>
              <i className="fa fa-save" /> Сохранить
            </StyledButton>
          )}
        </Zone>
      </PanelWrapper>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    rules: store.rules.rules,
    rulesForm: store.rules.rulesForm,
  };
};
export default connect(mapStateToProps, {
  getRules,
  toggleNode,
  toggleRack,
  toggleZone,
  updateRule,
})(withRouter(Zones));
