import styled from 'styled-components'

export const Reset = styled.div`
  position: relative;
  padding-left: 24px;
  height: 40px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  font-size: 15px;
  color: #3ea5ff;
  &:before { transform: rotate(45deg); }
  &:after { transform: rotate(-45deg); }
  &:before, &:after {
    content: ' ';
    position: absolute;
    left: 10px;
    top: 10px;
    height: 20px;
    width: 2px;
    background-color: #0085ef;
  }
`
