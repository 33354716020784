import { routes } from './routes';
import { Section } from './section';

// sections without subsections
import Stays from 'pages/stays/grid';
// import Cashbox from 'pages/cashbox/Cashbox';
import Clients from 'pages/clients/grid';
// --payments
import Rfid from 'pages/payments/rfid';
import Epm from 'pages/payments/epm';
import VehiclePlate from 'pages/payments/vehiclePlate';
import Subscription from 'pages/payments/subscription';
// --reports
import Rides from 'pages/reports/rides';
import PlateRides from 'pages/reports/plateRides';
import CashboxOperations from 'pages/reports/cashbox';
import Calls from 'pages/reports/calls';
import Events from 'pages/reports/events';
import Actions from 'pages/reports/actions';
import AdditionalReports from 'pages/reports/additionalReports';
import Summary from 'pages/reports/summary';
import Benefits from 'pages/reports/benefits';
import Promises from 'pages/reports/promises';
import Discounts from 'pages/reports/discounts';
// --options
import Tariffs from 'pages/options/tariffs/grid';
// import Tariffs2 from 'pages/options/tariffs2/editor';
import Rules from 'pages/options/rules';
import Roles from 'pages/options/roles';
import Users from 'pages/options/users';
import Categories from 'pages/options/categories';
import Collection from 'pages/options/collection';
import Parkings from 'pages/options/parkings';
import OptBenefits from 'pages/options/benefits';
import OptAdv from 'pages/options/adv';
import SOUpload from 'pages/options/soupload/soupload';

// cards
// --stays
import StayCard from 'pages/stays/card/stay-card';
// --clients
import ClientCard from 'pages/clients/card';
import CreateClientCard from 'pages/clients/card/CreateClientCard';
// --options
import TariffCard from 'pages/options/tariffs/card/TariffCard';
import CreateTariffCard from 'pages/options/tariffs/card/NewTariffCard';
import RuleCard from 'pages/options/rules/card';
//
import Operator from 'pages/operator';
import Empty from 'pages/Empty';

// routeList
export const routeList = [
  {
    type: 'card',
    id: 'empty',
    name: 'Нет прав',
    path: routes.empty,
    component: Empty,
  },
  {
    type: 'section',
    id: 'scheme',
    name: 'Мнемосхема',
    path: routes.scheme,
    withToken: true,
    component: null,
  },
  {
    type: 'section',
    id: 'stays',
    name: 'Парковки',
    path: routes.stays,
    component: Section,
    subRoutes: [{ path: routes.stays, component: Stays, exact: true }],
  },
  /*
  {
    type: 'section',
    id: 'cashbox',
    name: 'Касса',
    path: routes.cashbox,
    component: Cashbox,
  },*/
  {
    type: 'section',
    id: 'clients',
    name: 'Клиенты',
    path: routes.clients,
    component: Section,
    subRoutes: [{ path: routes.clients, component: Clients, exact: true }],
  },
  {
    type: 'section',
    id: 'payments',
    name: 'Средства оплаты',
    path: routes.payments,
    component: Section,
    subsections: [
      { id: 'rfid', name: 'RFID', path: routes.rfid, component: Rfid },
      { id: 'epm', name: 'БСК', path: routes.epm, component: Epm },
      { id: 'vehiclePlate', name: 'ГРЗ', path: routes.vehiclePlate, component: VehiclePlate },
      { id: 'subscription', name: 'Абонемент', path: routes.subscription, component: Subscription },
    ],
  },
  {
    type: 'section',
    id: 'reports',
    name: 'Журналы и отчеты',
    path: routes.reports,
    component: Section,
    subsections: [
      { id: 'rides', name: 'Проезды', path: routes.rides, component: Rides },
      { id: 'plateRides', name: 'Проезды по ГРЗ', path: routes.plateRides, component: PlateRides },
      {
        id: 'cashboxOperations',
        name: 'Кассовые операции',
        path: routes.cashboxOperations,
        component: CashboxOperations,
      },
      //{ id: 'refill', name: 'Пополнение ЛС', path: routes.refill, component: Refill },
      { id: 'calls', name: 'Звонки', path: routes.calls, component: Calls },
      { id: 'events', name: 'Системные события', path: routes.events, component: Events },
      { id: 'actions', name: 'Действия пользователя', path: routes.actions, component: Actions },
      //{ id: 'using', name: 'Использование и выручка', path: routes.using, component: Using1 },
      //{ id: 'working', name: 'Показатели работы автостоянки', path: routes.working, component: Working1 },
      { id: 'summary', name: 'Сводный', path: routes.summary, component: Summary },
      { id: 'benefits', name: 'Льготы', path: routes.benefits, component: Benefits },
      { id: 'discounts', name: 'Скидки', path: routes.discounts, component: Discounts },
      { id: 'promises', name: 'Договор паркирования', path: routes.promises, component: Promises },

      { id: 'additional', name: 'Отчеты', path: routes.additional, component: AdditionalReports },
      //{ id: 'using2', name: 'Использование и выручка (мес)', path: routes.using2, component: Using2 },
      //{ id: 'working2', name: 'Показатели работы автостоянки (мес)', path: routes.working2, component: Working2 }
    ],
  },
  {
    type: 'section',
    id: 'options',
    name: 'Настройки',
    path: routes.options,
    component: Section,
    subsections: [
      { id: 'tariffs', name: 'Тарифы', path: routes.tariffs, component: Tariffs },
      // { id: 'tariffs2', name: 'Тарифы редактор', path: routes.tariffs2, component: Tariffs2 },
      { id: 'rules', name: 'Правила въезда', path: routes.rules, component: Rules },
      { id: 'users', name: 'Пользователи', path: routes.users, component: Users },
      { id: 'collection', name: 'Инкассаторы', path: routes.collection, component: Collection },
      { id: 'roles', name: 'Роли', path: routes.roles, component: Roles },
      { id: 'categories', name: 'Категории', path: routes.categories, component: Categories },
      { id: 'optbenefits', name: 'Скидки', path: routes.optbenefits, component: OptBenefits },
      { id: 'parkings', name: 'Закрытие парковок', path: routes.optparkings, component: Parkings },
      { id: 'optadv', name: 'Реклама', path: routes.optadv, component: OptAdv },
      { id: 'soupload', name: 'Загрузка СО', path: routes.soupload, component: SOUpload, hidden: true },
    ],
  },
  { type: 'card', id: 'stay', path: routes.stay, component: StayCard },
  { type: 'card', id: 'newClient', path: routes.newClient, component: CreateClientCard },
  { type: 'card', id: 'client', path: routes.client, component: ClientCard },
  { type: 'card', id: 'newTariff', path: routes.newTariff, component: CreateTariffCard },
  { type: 'card', id: 'tariff', path: routes.tariff, component: TariffCard },
  { type: 'card', id: 'rule', path: routes.rule, component: RuleCard },
  {
    type: 'card',
    id: 'operator',
    name: 'Оператор',
    path: routes.operator,
    component: Operator,
  },
];
