import styled from 'styled-components';

export const TablePlace = styled.div`
  /* overflow-x: auto;
  max-width: 100%; */
`;

export const TableFooter = styled.div`
  padding: 15px 0;
`;

export const TableCmp = styled.table`
  /* table-layout: fixed; */
  table-layout: auto;
  border-collapse: collapse;
  width: 100%;
  text-align: left;
  th,
  td {
    min-width: 50px;
  }
`;

export const TableBody = styled.tbody``;
