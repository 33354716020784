import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-right: 35px;
`;
export const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #9a9a9a;
  border-top: 1px solid #9a9a9a;
  padding-left: 20px;
  width: 100%;
  height: 33px;
  span {
    font-size: 13px;
    color: #9a9a9a;
  }
`;
export const SectionWrapper = styled.div`
  width: 610px;
  margin-top: 20px;
`;
export const ScrollWrapper = styled.div`
  overflow: visible;
`;
