import { data } from 'services';
import { getCategoryById, getVehicleClassById } from 'services/types';
import { ParkingCell } from 'libs/ui';

const fields = [
  { name: 'status', title: 'Статус', width: 145, sortField: 'status', render: (val) => data.getDictValue('rfid.status', val) },
  { name: 'rfid', title: 'RFID', width: 120, sortField: 'rfid' },
  { name: 'client_name', title: 'Клиент', width: 147, sortField: 'client_name' },
  { name: 'owner', title: 'Водитель', sortField: 'owner' },
  { name: 'vehicle_category', title: 'Категория ТС', width: 112, sortField: 'vehicle_category', render: (val) => getCategoryById(val) },
  { name: 'vehicle_class', title: 'Класс ТС', width: 112, sortField: 'vehicle_class', render: (val) => getVehicleClassById(val) },
  { name: 'vehicle_plate', title: 'ГРЗ', width: 130, sortField: 'vehicle_plate' },
  { name: 'tariff', title: 'Тариф', width: 180 },
  { name: 'comment', title: 'Комментарий', width: 150 },
];

if (window.config.central) {
  fields.unshift({ name: 'parking_id', title: 'Парковка', render: (val) => <ParkingCell parking_id={val} cmp={'span'} />, width: 140 });
}

// get row class
const getRowClass = (row) => {
  let out = '';
  out += ' ' + row.status;
  return out;
};

export const tableParams = { fields, getRowClass, withComment: true };
