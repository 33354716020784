import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import styled from 'styled-components';
import moment from 'moment';

import {
  DateSelect,
  ParkingFilter,
  Container,
  Input,
  //Multiselect,
  //Select,
  DoubleField,
  DateTime,
  //Toggle,
  ButtonsContainer,
} from 'libs/form';
import { UseBtn, ResetBtn } from 'libs/ui';

const StyledContainer = styled(Container)`
  form {
  }
`;

class Filter extends Component {
  constructor(props) {
    super(props);

    // initial
    this.props.setInitial(initialValues);
  }

  render() {
    const { handleSubmit, reset, change } = this.props;

    return (
      <StyledContainer /*height='200px'*/>
        <form onSubmit={handleSubmit}>
          <DateSelect from_name="datetime_from" to_name="datetime_to" change={change}>
            <DoubleField>
              <DateTime width="160px" name="datetime_from" type="dateTime" withoutIcon label="Время" />
              <DateTime width="160px" name="datetime_to" type="dateTime" withoutIcon />
            </DoubleField>
          </DateSelect>

          <Input name="fn" label="Номер ФН" placeholder="Номер ФН" type="text" />
          <Input width="220px" name="name" type="text" label="Клиент" placeholder="Клиент" />

          <DoubleField>
            <Input width="80px" name="amount_from" type="text" label="Сумма, ₽" placeholder="От" naturalNumber />
            <Input width="80px" name="amount_to" type="text" placeholder="До" naturalNumber />
          </DoubleField>

          <ParkingFilter />

          <ButtonsContainer>
            <ResetBtn onClick={reset} />
            <UseBtn />
          </ButtonsContainer>
        </form>
      </StyledContainer>
    );
  }
}

const initialValues = {
  //datetime_from: moment().startOf('day').format(),
  //datetime_to: moment().endOf('day').format(),
};

export default reduxForm({
  form: 'discountFilter',
  destroyOnUnmount: false,
  initialValues: initialValues,
})(Filter);
