import React from 'react';

export const Input = (props) => {
  const { value, input, type, placeholder, className, label, disabled, maxLength, tabIndex, autoFocus, autoComplete } = props;

  return (
    <div className={className}>
      <label>{label}</label>
      <input
        autoComplete={autoComplete}
        maxLength={maxLength}
        disabled={disabled}
        type={type}
        placeholder={placeholder}
        value={value}
        tabIndex={tabIndex}
        autoFocus={autoFocus}
        {...input}
      />
    </div>
  );
};
