import styled from 'styled-components';

import { Date } from 'libs/form';
import { UpdateMessage } from 'libs/ui';

export const UpdateMessageBox = styled(UpdateMessage)`
  top: 20px;
  left: 45%;
`;

export const DateInput = styled(Date)`
  label {
    margin-bottom: 2px;
    display: block;
    color: #3e3e3e;
    font-size: 13px;
    opacity: 0.6;
    white-space: nowrap;
  }
  input,
  select,
  textarea,
  .input {
    font-size: 15px;
    color: #000;
    width: 100%;
    padding: 11px 10px 9px;
    border-radius: 4px;
    border: solid 1px #bebebe;
    height: 40px;
  }
`;

export const Confirm = styled.div`
  padding: 0;
`;
export const Title = styled.div`
  padding: 0;
  margin: 0 0 1rem;
`;
export const Buttons = styled.div`
  padding: 0;
  display: flex;
  align-items: center;
  button {
    margin-right: 1rem;
  }
`;
