import styled from 'styled-components';

export const NewTicketInfoAdd = styled.label`
  font-size: 15px;
  display: flex;
  font-weight: normal;
  margin-bottom: 10px;
  padding-left: 17px;
`;

export const Line1 = styled.div`
  margin: 0 5px 0 0;
`;

export const Line2 = styled.div`
  margin: 0 0 10px;
  display: flex;
  align-items: center;
  .l {
    display: block;
    width: 220px;
    margin-right: 15px;
    flex-shrink: 0;
    &.sm {
      width: 150px;
    }
  }
`;
export const PromiseContent = styled.div``;

export const NewTicketContent = styled.div`
  margin-left: 90px;
  text-align: left;
  form {
    margin-bottom: 0px;
  }
`;
export const NewTicketHeader = styled.p`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
`;
export const NewTicketInfo = styled.label`
  font-size: 15px;
  display: block;
  cursor: pointer;
  font-weight: normal;
  margin-bottom: 10px;
  select {
    margin-left: 0.5rem;
  }
`;
export const AttentionIcon = styled.img`
  width: 50px;
  height: 46px;
  position: absolute;
  left: 35px;
  top: 70px;
`;

export const Line = styled.span`
  display: inline-flex;
  [type='radio'] {
    margin-right: 0.2rem;
  }
  [name='penaltyvalue'] {
    width: 70px;
    margin: 0 0.5rem;
    align-items: center;
  }
`;
