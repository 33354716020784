import React, { Component } from 'react';
import styled from 'styled-components';
import { change } from 'redux-form';
import Calendar from 'rc-calendar';
import moment from 'moment';
import ruRU from 'rc-calendar/lib/locale/ru_RU';
import TimePickerPanel from 'rc-time-picker/lib/Panel';

const InputBoxContainer = styled.div`
  &.in-filter .fa {
    right: 10px;
    top: 50%;
    margin-top: -8px;
    color: #000;
  }
`;

const InputBox = styled.div`
  position: relative;
  i {
    position: absolute;
    top: 2px;
    right: 0px;
    color: #fff;
    opacity: 0.6;
  }
`;

export class Date extends Component {
  state = {
    showCalendar: false,
  };

  popup = null;
  timePickerElement = (<TimePickerPanel defaultValue={moment('00:00:00', 'HH:mm')} />);
  timePickerElement2 = (<TimePickerPanel defaultValue={moment('23:59:59', 'HH:mm')} />);

  componentDidMount() {
    this.time = moment();//'23:59:59', 'HH:mm');
  }

  setDate = (date) => {
    const { name } = this.props.input;
    const { dispatch, form } = this.props.meta;
    const { onChange } = this.props;
    if (onChange) {
      onChange(date);
    }
    if (dispatch) {
      dispatch(change(form, name, date));
    }
  };

  toggleCalendar = () => {
    const { disabled } = this.props;
    if (disabled) return;
    if (!this.state.showCalendar) {
      document.addEventListener('click', this.handleOutsideClick, true);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, true);
    }
    this.setState({ showCalendar: !this.state.showCalendar });
  };

  handleOutsideClick = (e) => {
    if (this.popup) {
      if (this.popup.contains(e.target)) {
        return;
      } else {
        this.toggleCalendar();
      }
    }
  };

  getDisplayedValue = (type) => {
    if (this.props.input.value) {
      return type === 'dateTime'
        ? moment(this.props.input.value)
            .format('lll')
            .replace(/[г., ]{4}/, '')
            .replace(/\./, '')
        : moment(this.props.input.value)
            .format('ll')
            .replace(/[г., ]{4}/, '')
            .replace(/\./, '');
    } else return '';
  };

  render() {
    const { className, label, placeholder, type, withoutIcon, ...rest } = this.props;
    const timeel = this.props.input.name.indexOf('_to') === -1 ? this.timePickerElement : this.timePickerElement2;
    const changeTime = className.indexOf('cl2359') !== -1;
    // console.log('🚀 ~ render ~ moment(rest.input.value):', rest.input.value, moment(rest.input.value));
    return (
      <InputBoxContainer className={className}>
        <label>{label}</label>
        <div>
          <InputBox onClick={this.toggleCalendar}>
            <input placeholder={placeholder} value={this.getDisplayedValue(type)} disabled />
            {withoutIcon ? null : <i className="fa fa-calendar" />}
          </InputBox>
          {this.state.showCalendar ? (
            <div
              ref={(popup) => {
                this.popup = popup;
              }}
              style={{ position: 'absolute' }}
            >
              <Calendar
                value={moment(rest.input.value)}
                selectedValue={moment(rest.input.value)}                
                timePicker={this.props.type === 'dateTime' ? timeel : null}
                onSelect={(date) => {
                  //console.log('🚀 ~ render ~ date', date);
                  this.setDate(date);
                }}
                onChange={(date) => {
                  if (changeTime && this.time.minutes() === date.minutes() && this.time.hours() === date.hours()) {
                    date.hours(23);
                    date.minutes(59);
                  }
                  this.setDate(date);
                }}
                locale={ruRU}
                className={'calendar'}
                showDateInput={false}
              />
            </div>
          ) : null}
        </div>
      </InputBoxContainer>
    );
  }
}
