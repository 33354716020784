import React, { Component } from 'react';
import moment from 'moment';

import { Container, ButtonsContainer } from 'libs/form';
import { Button, themes } from 'libs/ui';
import { api } from 'api';
import { Buttons, Confirm, DateInput, Title, UpdateMessageBox } from './Filter.styled';

class Filter extends Component {
  state = {
    date: moment(),
    confirm: false,
    result: false,
  };

  showMessage = () => {
    this.setState({ result: true });
    setTimeout(() => {
      this.setState({ result: false });
    }, 3000);
  };

  action = () => {
    const { date } = this.state;

    const d = date.toISOString();
    //console.log('🚀 ~ date', d)

    api.call('stays.auto_close', { max_entry_at: d }).then((__data) => {
      //console.log('🚀 ~ api ~ data', data);
      this.setState({ confirm: false }, () => {
        this.showMessage();
      });
    });
  };

  setDate = (date) => {
    //console.log('🚀 ~ date:', date);
    this.setState({ date: date });
  };

  closeConfirm = () => {
    this.setState({ confirm: false });
  };

  showConfirm = (e) => {
    e.preventDefault();
    this.setState({ confirm: true });
  };

  render() {
    const { date, confirm, result } = this.state;
    const d = date ? moment(date).format('ll') : '';
    return (
      <Container>
        {result && <UpdateMessageBox>Парковки завершены</UpdateMessageBox>}
        {confirm ? (
          <Confirm>
            <Title>Все активные парковки с датой начала (въездом) до 0:00 {d} будут завершены. Подтвердить?</Title>
            <Buttons>
              <Button theme={themes.blue} onClick={this.closeConfirm}>
                Отменить
              </Button>
              <Button theme={themes.red} onClick={this.action}>
                Завершить
              </Button>
            </Buttons>
          </Confirm>
        ) : (
          <form onSubmit={this.showConfirm}>
            <DateInput
              meta={{}}
              input={{ name: 'end_at', value: date }}
              className="in-filter"
              width="175px"
              name="end_at"
              type="date"
              onChange={this.setDate}
              label="Завершить все парковки до"
              //placeholder="Завершить все парковки до"
            />
            <ButtonsContainer>
              <Button disabled={!date} type="submit" theme={themes.blue}>
                Завершить
              </Button>
            </ButtonsContainer>
          </form>
        )}
      </Container>
    );
  }
}

export default Filter;
