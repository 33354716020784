import React from 'react';
import styled from 'styled-components';

import zonesService from 'services/zones';
import { RowContentWrapper } from './Row';

const ZoneRowWrapper = styled(RowContentWrapper)`
  float: right;
  padding: 10px 4px;
`;
const Zone = styled.div`
  font-weight: bold;
  font-size: 13px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: ${(props) => props.color};
  border-radius: 50%;
`;
/*
const DoubledZone = styled(Zone)`
  width: 70px;
  border-radius: 20px;
`
*/

function ZonesRow(props) {
  const colors = zonesService.getZonesColors();
  const zones = zonesService.getZonesFromRule(props.rules);

  const zonesList = zones.map((item) => {
    const id = item.zone_id;
    const title = id;
    const color = colors[id] ? colors[id] : '#4d8b8b';
    return (
      <Zone key={id} color={color}>
        {title}
      </Zone>
    );
  });

  return <ZoneRowWrapper>{zonesList}</ZoneRowWrapper>;
}

export default ZonesRow;
