import { useState, useEffect } from 'react';

import { subscriptionsBill, subscriptionsExtend } from 'services/payments-service';
import { Loader, Button, themes, Popup2 } from 'libs/ui';
import { Content, Buttons, Title, Info } from './extension-form.styled';

export const ExtensionForm = (props) => {
  const { id, mean_id, close } = props;
  const [info, setInfo] = useState(null);

  useEffect(() => {
    subscriptionsBill(id).then((data) => {
      setInfo(data);
    });
  }, [id]);

  /**
   * activate
   * @returns
   */
  const activate = () => {
    return subscriptionsExtend(id)
      .then(() => {
        close(true);
      })
      .catch((errors) => {
        if (errors && errors.data) {
          console.log('🚀 ~ activate ~ errors:', errors);
        }
      });
  };

  if (!info) {
    return (
      <Popup2 w={470}>
        <Content>
          <Loader height={'80px'} />
          <Buttons>
            <Button type="button" onClick={() => close()}>
              Отменить
            </Button>
          </Buttons>
        </Content>
      </Popup2>
    );
  }

  const balance = Math.round(info.amount / 100);
  const date = info.pass_date;

  return (
    <Popup2 w={470}>
      <Content>
        <Title>Активировать абонемент №{mean_id}</Title>
        <Info>
          <p>
            Абонемент будет активирован до <b>{date}</b>,
          </p>
          <p>
            Баланс: <b>{balance}</b>₽. Продолжить?
          </p>
        </Info>
        <Buttons>
          <Button type="button" onClick={() => close()}>
            Отменить
          </Button>
          <Button theme={themes.blue} onClick={activate}>
            Активировать
          </Button>
        </Buttons>
      </Content>
    </Popup2>
  );
};
