import React from 'react';
import styled, { css } from 'styled-components';
//import { Link } from 'react-router-dom';

import ZoneInfo from './ZoneInfo';
import zones from 'services/zones';
import { routes } from 'router';

export const RowWrapper = styled.div`
  color: black;
  cursor: pointer;
  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
    `}
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 41px;
  border-bottom: 1px solid rgba(151, 151, 151, 0.6);
  &:nth-child(2n) {
    background-color: rgba(0, 0, 0, 0.02);
  }
  p {
    padding-left: 20px;
  }
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 295px;
`;
const Name = styled.p`
  font-size: 15px;
  color: #000000;
`;
const Discription = styled.p`
  opacity: 0.46;
  font-size: 12px;
  line-height: 1.4;
`;
const CheckBox = styled.div`
  width: 14px;
  height: 14px;
  input {
    appearance: none;
    outline: none;
    cursor: pointer;
    width: 14px;
    height: 14px;
    border-radius: 4px;
    border: 1px solid #fff;
    &:checked {
      background-image: url('/images/checkbox-checked.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-color: #fff;
      border: none;
    }
  }
`;
const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.color};
  width: 40px;
  height: 40px;
`;
export const RowContentWrapper = styled.div`
  display: flex;
  width: 360px;
  justify-content: space-between;
`;
/*
const DoubledCheckboxWrapper = styled(CheckboxWrapper)`
  width: 80px;
`;*/
function Row(props) {
  function getValue(zone) {
    let result = {
      nodes: {},
    };
    zone.nodes.forEach((node) => {
      let enabledRacks = [];
      let complete = true;

      node.racks.forEach((rack) => {
        if (rack.enabled) {
          enabledRacks.push('С' + rack.rack_id);
        } else {
          complete = false;
        }
      });
      if (enabledRacks.length) {
        if (complete) {
          result.nodes[node.node_id] = true;
        } else {
          result.nodes[node.node_id] = enabledRacks;
        }
      }
    });

    if (Object.keys(result.nodes).length) {
      return <ZoneInfo nodes={result.nodes} />;
    }
  }

  const getCheckboxes = () => {
    const zonesArray = props.zones;
    const checkboxList = zonesArray.map((item) => {
      let Wrapper = CheckboxWrapper; //item.zone_id === 'P6' ? DoubledCheckboxWrapper : CheckboxWrapper
      return (
        <div
          onClick={() => props.action(item, props.rule_id)}
          key={item.zone_id}
          to={`${routes.rule}?id=${props.rule_id}&zone=${item.zone_id}`}
        >
          <Wrapper color={zones.getColor(item.zone_id)}>
            <CheckBox>{getValue(item)}</CheckBox>
          </Wrapper>
        </div>
      );
    });

    return checkboxList;
  };

  const { disabled } = props;

  return (
    <RowWrapper disabled={disabled}>
      <TextWrapper>
        <Name>{props.name}</Name>
        <Discription>{props.discription}</Discription>
      </TextWrapper>
      <RowContentWrapper>{getCheckboxes()}</RowContentWrapper>
    </RowWrapper>
  );
}

export default Row;
