import React from 'react';

import { user } from 'services';
import { GridRow } from 'libs/ui';
import { Cell } from 'libs/ui';
import { OptionsCell } from 'libs/ui';

const Row = (props) => {
  const editEntity = () => {
    props.setCategoryInfo({
      id: props.id,
      name: props.name,
      comment: props.comment,
    });
    props.updateFormDisplayHandler(true);
  };

  const getMenu = () => {
    const out = [];
    if (user.haveRight('vehicle_category.update')) {
      out.push({ text: 'Редактировать', func: editEntity });
    }
    return out;
  };

  const menu = getMenu();

  return (
    <GridRow>
      <Cell>{props.id}</Cell>
      <Cell>{props.name}</Cell>
      <Cell>{props.comment}</Cell>
      <OptionsCell disabled={props.formIsShow || !menu.length} options={menu} left={-66} />
    </GridRow>
  );
};

export default Row;
