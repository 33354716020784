import React from 'react';

import { user, data } from 'services';
import { OptionsCell, Cell, GridRow } from 'libs/ui';

const Row = (props) => {
  const editEntity = () => {
    props.setUserInfo({
      id: props.id,
      is_active: props.is_active,
      role: props.role,
      username: props.username,
      password: '',
      name: props.name,
      email: props.email,
      phone: props.phone,
      role_id: props.role_id,
    });
    props.updateFormDisplayHandler(true);
  };

  const getMenu = () => {
    const out = [];
    if (user.haveRight('users.update')) {
      out.push({ text: 'Редактировать', func: editEntity });
    }
    return out;
  };

  const menu = getMenu();

  return (
    <GridRow status={props.is_active ? 'active' : 'blocked'}>
      <Cell>{data.getDictValue('users.status', props.is_active)}</Cell>
      <Cell>{props.role}</Cell>
      <Cell>{props.username}</Cell>
      <Cell>{props.name}</Cell>
      <Cell>{props.email}</Cell>
      <Cell>{props.phone}</Cell>
      <OptionsCell disabled={props.formIsShow || !menu.length} options={menu} left={-66} />
    </GridRow>
  );
};

export default Row;
