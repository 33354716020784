import React, { Component } from 'react';

import { ErrorWrapper, Message, StyledFieldWrapper } from './source-input-wrapper.styled';

export class Wrapper extends Component {
  constructor(props) {
    super(props);
    this.state = { showTip: false };
  }

  getErrorMessage = (error) => {
    switch (error) {
      case 'wrong_value':
        return 'Неправильное значение';
      case 'duplicate':
        return 'Значение уже используется';
      default:
        return error;
    }
  };

  render() {
    const Field = this.props.component;
    const {
      Tip,
      meta: { error, touched },
    } = this.props;
    return (
      <StyledFieldWrapper>
        <ErrorWrapper
          className={touched && error ? 'error' : ''}
          onMouseEnter={() => this.setState({ showTip: true })}
          onMouseLeave={() => this.setState({ showTip: false })}
        >
          <Field {...this.props} />
          {touched && error && this.state.showTip ? <Message>{this.getErrorMessage(error)}</Message> : null}
        </ErrorWrapper>
        {Tip ? <Tip /> : null}
      </StyledFieldWrapper>
    );
  }
}
